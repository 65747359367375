import React, { forwardRef, useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";
import classnames from "classnames/bind";

import styles from "./telia-button.module.scss";

export const buttonStyles = styles;

export const ButtonVariant = {
  EXPRESSIVE: "expressive",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DESTRUCTIVE: "destructive",
  TERTIARY_PURPLE: "tertiary-purple",
  PRIMARY_WHITE: "primary-white",
  SECONDARY_WHITE: "secondary-white",
  EXPRESSIVE_WHITE: "expressive-white",
  TEXT: "text",
} as const;
type ButtonVariantType = typeof ButtonVariant[keyof typeof ButtonVariant];
type ButtonSizes = "sm" | "md";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  allyControls?: string;
  allyExpanded?: boolean;
  allyLabel?: string;
  allyHaspopup?:
    | boolean
    | "dialog"
    | "menu"
    | "true"
    | "false"
    | "listbox"
    | "tree"
    | "grid"
    | undefined;
  dataTestid?: string;
  formEnctype?: string;
  fullWidth?: boolean;
  size?: ButtonSizes;
  variant: ButtonVariantType;
  ["data-track-value"]?: string;
  ["data-track-click"]?: string;
  ["data-track-configuration"]?: string;
};
const rootClassName = "b2x-telia-button";
const cx = classnames.bind(styles);
const TeliaButtonComponent = <T extends HTMLButtonElement>(
  {
    allyControls,
    allyExpanded = false,
    allyHaspopup,
    allyLabel = "",
    children,
    className,
    dataTestid,
    disabled = false,
    form,
    formAction,
    formEnctype,
    formMethod,
    formNoValidate = false,
    formTarget,
    fullWidth = false,
    id,
    onBlur,
    onClick,
    onFocus,
    onKeyDown,
    onMouseDown,
    size = "md",
    type = "submit",
    value,
    variant,
    ["data-track-value"]: dataTrackValue,
    ["data-track-click"]: dataTrackClick,
    ["data-track-configuration"]: dataTrackConfiguration,
  }: Props,
  ref: React.ForwardedRef<T>
) => {
  useEffect(() => {
    obsoleteWarning(rootClassName, "inputs-button--docs");
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
    onClick?.(event);
  };

  return (
    <button
      aria-controls={allyControls}
      aria-expanded={allyExpanded}
      aria-label={allyLabel}
      aria-haspopup={allyHaspopup}
      className={cx(className, {
        "b2x-telia-button": true,
        [`b2x-telia-button--${variant}`]: !!variant,
        "b2x-telia-button--full-width": fullWidth,
        [`b2x-telia-button--${size}`]: true,
      })}
      data-testid={dataTestid}
      data-track-value={dataTrackValue}
      data-track-click={dataTrackClick}
      data-track-configuration={dataTrackConfiguration}
      disabled={disabled}
      form={form}
      formAction={formAction}
      formEncType={formEnctype}
      formMethod={formMethod}
      formNoValidate={formNoValidate}
      formTarget={formTarget}
      id={id}
      onClick={handleClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseDown={onMouseDown}
      onKeyDown={onKeyDown}
      ref={ref}
      type={type === "submit" ? "submit" : "button"}
      value={value}
    >
      {children}
    </button>
  );
};

export const TeliaButton = forwardRef(TeliaButtonComponent);
TeliaButton.displayName = "TeliaButton";
